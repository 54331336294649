import React, { useEffect } from "react"
import "../css/component/main_visual.scss"

// 첨부파일 문서 업데이트 시 수정
import whitePaperENG from "../files/240827 [MBTCs] Whitepaper_v2.8(EN).pdf"
import whitePaperKOR from "../files/230414 [MBTCs] Whitepaper_v1.0(KR).pdf"
import whitePaperCHN from "../files/230414 [MBTCs] Whitepaper_v1.0(CN).pdf"

// 번역할 메시지를 가져온다
import { FormattedMessage } from "react-intl"

const MainVisual = () => {
  useEffect(() => {
    const video = document.getElementById("mainVideo")
    video.play()
  }, [])

  return (
    <div className="main_visual">
      <video id="mainVideo" muted playsInline loop>
        <source
          src={`${process.env.PUBLIC_URL}/assets/main_video.mp4`}
          type="video/mp4"
          alt="MBTCs video"
        />
      </video>

      <div className="main_slogan">
        <h2>
          <FormattedMessage id="Main_V_Title01" />
          <br />
          <FormattedMessage id="Main_V_Title02" />
        </h2>
        <p>
          <FormattedMessage id="Main_V_Title03" />
          <br />
          <FormattedMessage id="Main_V_Title04" />
        </p>
        <div className="file">
          <a
            href={whitePaperENG}
            target="_blank"
            rel="noreferrer noopener"
            className="file_en file_text"
          >
            White paper (ENG)
          </a>
          {/* <a href={whitePaperKOR} target="_blank" rel="noreferrer noopener" className='file_ko file_text'>
            Whitepaper(KOR)
          </a>
          <a href={whitePaperCHN} target="_blank" rel="noreferrer noopener" className='file_ko file_text'>
            Whitepaper(CHN)
          </a> */}
        </div>
      </div>

      <div className="scroll">
        <div className="line">
          <span></span>
        </div>
      </div>
    </div>
  )
}

export default MainVisual
